'use client';
import { ChangeEvent } from 'react';
import { locales } from '@/config';
import { useRouter, useParams, usePathname, useSearchParams } from 'next/navigation';

export function LanguageSwitcher() {
  const router = useRouter();
  const path = usePathname();
  const searchParams = useSearchParams();
  const { locale } = useParams<{ locale: string }>();

  const handleSelectionChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const locale = event.target.value;
    const separatedPath = path.split('/');
    separatedPath[1] = locale;
    let newPath = separatedPath.join('/');
    // newPath = '/' + newPath;
    if (searchParams) {
      newPath += '?' + searchParams.toString();
    }

    router.replace(newPath);
  };

  const localeInSelector = (locale: string) => {
    switch (locale) {
      case 'en':
        return '🇨🇦 English';
      case 'fr':
        return '🇨🇦 Français';
      default:
        return '🌐 Pseudo';
    }
  };

  return (
    <select
      value={locale}
      onChange={handleSelectionChange}
      className="max-w-fit px-1 py-2 border-3 border-black bg-wheat font-semibold"
    >
      {locales.map((locale, id) => (
        <option key={id} value={locale} className="">
          {localeInSelector(locale)}
        </option>
      ))}
    </select>
  );
}
