'use client';
import React from 'react';
import { FeedbackType, feedbackTypes } from './FeedbackForm';
import { Button } from '@nextui-org/react';
import { useTranslations } from 'next-intl';

interface Props {
  onFeedbackTypeChanged: (feedbackType: FeedbackType) => void;
}

export const FeedbackTypeStep: React.FC<Props> = ({ onFeedbackTypeChanged }: Props) => {
  const t = useTranslations('Feedback.Type');
  return (
    <>
      <header>
        <span className="text-xl text-dark-slate-blue leading-6">{t('GiveFeedback')}</span>
      </header>

      <div className="flex py-8 gap-2 w-full">
        {Object.entries(feedbackTypes).map(([key, value], idx) => {
          return (
            <div
              key={idx}
              className="group relative flex place-self-center place-content-center border-2 border-black bg-light-steel-blue"
            >
              <Button
                key={key}
                className="bg-orange-100 h-24 rounded-lg flex flex-1 flex-col items-center border-2 border-transparent hover:border-slate-500 focus:border-slate-500 focus:outline-none"
                onClick={() => onFeedbackTypeChanged(key as FeedbackType)}
                type="button"
              >
                <value.image.src width={512} height={512} />
                <span className="pb-2">{t(`${key}.title`)}</span>
              </Button>
              <div
                onClick={() => onFeedbackTypeChanged(key as FeedbackType)}
                className="my-px border-2 border-black  inset-0 absolute  group-hover:skew-x-2  group-hover:bg-goldenrod group-hover:bg-opacity-50 group-hover:skew-y-2 hover:cursor-pointer"
              ></div>
            </div>
          );
        })}
      </div>
    </>
  );
};
