export enum Portal {
  Public,
  Parents,
  Kids,
  Admin,
}

export const ADMIN_EMAILS = ['avrohom@madacenter.com', 'iepstein@madacenter.com'];

export const CHILD_ID_COOKIE = 'child_id';

export const causes = [
  {
    cause: 'ShabbatToShare',
    category: `food`,
    title: 'SHABBAT TO SHARE',
    subtitle: `Sending Warmth in a Box`,
    description: `Each week, we bring a touch of home and warmth to our dear elders with our Shabbat boxes. Filled with treats and love, it’s a reminder that they are cherished. Help us ensure every elder feels the warmth of the community!`,
    image:
      'https://assets-global.website-files.com/63b371ba462a08fa7e164959/64d3eb12d799ec4adab85c97_shabbat-to-share5-1%5B1%5D.jpg',
    alt: 'woman holding shabbat to share meal box',
  },
  {
    cause: 'FurnitureDepot',
    category: `furniture`,
    title: `FURNITURE DEPOT`,
    subtitle: `Making Every Space Feel Like Home!`,
    description: `Our Furniture Depot helps families make their houses truly feel like homes. From sofas to stoves, we help families pick what they need. Join us in turning spaces into cozy, loving homes!`,
    image:
      'https://assets-global.website-files.com/63b371ba462a08fa7e164959/64d3eaddbdc54f860266e6b2_loading-furniture-into-removal-truck%5B1%5D.jpg',
    alt: 'men moving furniture together into warehouse',
  },
  {
    cause: 'ClothingDepot',
    category: `clothing`,
    title: `CLOTHING DEPOT`,
    subtitle: `Dress Up and Spread Joy!`,
    description: `Picture a place where everyone can find the perfect outfit. At our Clothing Depot, everyone gets to choose from cool outfits, shoes, toys, and more - all for free. Let's keep our community looking good and feeling great!`,
    image:
      'https://assets-global.website-files.com/63b371ba462a08fa7e164959/63d2f417fe9487324f021b44_63c06d84958d1b35ce38cc0d_Japanese-clothing-store.jpeg',
    alt: 'clothing rack filled with sweaters',
  },
  {
    cause: 'MealsPartager',
    category: `food`,
    title: `MEALS A PARTAGER`,
    subtitle: `Serving Care on a Plate!`,
    description: `The "Meals à Partager" program is about more than just food; it’s about ensuring everyone gets a nutritious meal. We deliver meals right to their door, bringing joy one plate at a time. Let's keep everyone well-fed and cared for!`,
    image:
      'https://assets-global.website-files.com/63b371ba462a08fa7e164959/64d3eaa12f8624f6f85acc85_about_image%5B1%5D.jpg',
    alt: "woman holding box labeled 'meals a partager'",
  },
  {
    cause: 'Cafeteria',
    category: `food`,
    title: `CAFETERIA`,
    subtitle: `Share a Meal, Share a Smile!`,
    description: `Want a place where everyone enjoys good food and great company? Our cafeteria serves warm meals three times a day, turning hunger into happiness. With your help, we can keep everyone smiling around the table!`,
    image:
      'https://assets-global.website-files.com/63b371ba462a08fa7e164959/64d3eb75ba0291503aee1389_group-of-cheerful-seniors-enjoying-breakfast-in-nursing-home-care-center%5B1%5D.jpg',
    alt: 'man with two elderly people a a table with food',
  },
  {
    cause: 'ChezMakolet',
    category: `essentials`,
    title: `CHEZ MAKOLET`,
    subtitle: `More Than Just a Food Bank!`,
    description: `At Chez Makolet, families can pick what they need, from fresh fruits to everyday essentials. Every box is a bundle of care. Let's make sure every family has what they need to thrive!`,
    image:
      'https://assets-global.website-files.com/63b371ba462a08fa7e164959/64d3eb462f8624f6f85b7289_Basket-008-1024x682%5B1%5D.jpg',
    alt: 'box filled with food and snacks',
  },
];
