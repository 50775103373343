'use client';
import React from 'react';
import { FeedbackForm } from './FeedbackForm';
import { ChatTeardropDots } from './FeedbackWidgetIcons';
import { Popover, PopoverTrigger, PopoverContent, Button } from '@nextui-org/react';
import { useTranslations } from 'next-intl';

export const FeedbackWidget: React.FC = () => {
  const t = useTranslations('Feedback');
  return (
    <Popover placement="top" className="flex flex-col items-end">
      <PopoverTrigger className="bg-dark-slate-blue px-3 h-12 text-old-lace flex items-center group rounded-br-2xl rounded-tl-2xl rounded-bl-none rounded-tr-none">
        <Button isIconOnly className="w-30">
          <ChatTeardropDots width={24} height={24} fill="white" />
          <span className="max-w-0 overflow-hidden group-hover:max-w-xs transition-all duration-500 ease-linear">
            <span className="pl-2">{t('Feedback')}</span>
          </span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="[all:unset]">
        <FeedbackForm />
      </PopoverContent>
    </Popover>
  );
};
