'use client';

import React from 'react';

import { Success } from './FeedbackWidgetIcons';
import { FormButton } from '@/components/FormButton';
import { useTranslations } from 'next-intl';

interface Props {
  onResetFeedbackType: () => void;
}

export const FeedbackSuccessStep: React.FC<Props> = ({ onResetFeedbackType }: Props) => {
  const t = useTranslations('Feedback.Success');
  return (
    <>
      <div className="flex flex-col items-center py-10 w-[304px]">
        <Success width={40} height={40} />
        <span className="text-xl my-2">{t('Appreciation')}</span>
        <FormButton type="button" onClick={onResetFeedbackType} text={t('SendMoreFeedback')} />
      </div>
    </>
  );
};
