'use client';
import React, { useState } from 'react';
import { FeedbackTypeStep } from './FeedbackTypeStep';
import { FeedbackSuccessStep } from './FeedbackSuccessStep';
import { FeedbackContentStep } from './FeedbackContentStep';
import { Bug, Idea, Thought } from './FeedbackWidgetIcons';

export const feedbackTypes = {
  bug: {
    title: 'Bug',
    image: {
      src: Bug,
      alt: 'Image of a bug',
    },
    placeholder: "Is something not working properly? We want to fix it. Tell us in detail what's going on.",
  },
  idea: {
    title: 'Idea',
    image: {
      src: Idea,
      alt: 'Image of a lighted lamp',
    },
    placeholder: 'Got an idea for improvement or a new feature? Tell us!',
  },
  thought: {
    title: 'Thought',
    image: {
      src: Thought,
      alt: 'Image of a thought cloud',
    },
    placeholder: 'We want to hear from you. What would you like to tell us?',
  },
};

export type FeedbackType = keyof typeof feedbackTypes;

export const FeedbackForm: React.FC = () => {
  const [feedbackType, setFeedbackType] = useState<FeedbackType | null>(null);
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false);

  const onResetFeedbackType = () => {
    setIsFeedbackSubmitted(false);
    setFeedbackType(null);
  };

  return (
    <div className="max-w-fit bg-old-lace p-4 border-2 border-black relative rounded-tr-2xl rounded-bl-2xl mb-4 flex flex-col items-center shadow-lg w-[calc(100vw-1rem)]">
      {isFeedbackSubmitted ? (
        <FeedbackSuccessStep onResetFeedbackType={onResetFeedbackType} />
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {!feedbackType ? (
            <FeedbackTypeStep onFeedbackTypeChanged={setFeedbackType} />
          ) : (
            <FeedbackContentStep
              feedbackType={feedbackType}
              onResetFeedbackType={onResetFeedbackType}
              onSubmitFeedback={() => setIsFeedbackSubmitted(true)}
            />
          )}
        </>
      )}
    </div>
  );
};
