'use client';

import { useRouter } from 'next/navigation';

export default function useFetcher() {
  const router = useRouter();

  const fetcher = (url: string, options: any) =>
    fetch(url, { ...options }).then((res) => res.status === 200 && res.json());

  const postFetcher = async (url: string, data: any, options?: any | undefined) => {
    return fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      ...options,
    }).then((res) => {
      if (options && options.followRedirect) {
        if (res.redirected) {
          router.push(res.url);
        } else {
          router.push('/');
        }
      } else if (!options || !options.dontJson) {
        return res.status == 200 && res.json();
      }
      // had to put this here to compile
      return null;
    });
  };

  return { fetcher, postFetcher };
}
