import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/[locale]/layoutHeader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/app/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FeedbackWidget"] */ "/app/components/FeedbackWidget.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.17.3_next@14.2.5_@opentelemetry+api@1.7.0_react-dom@18.2.0_react@18.2.0__react@18.2.0__react@18.2.0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.5_@opentelemetry+api@1.7.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.5_@opentelemetry+api@1.7.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"Karla\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\"],\"style\":[\"italic\",\"normal\"],\"display\":\"swap\",\"variable\":\"--font-karla\"}],\"variableName\":\"karla\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.5_@opentelemetry+api@1.7.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"Titillium_Web\",\"arguments\":[{\"weight\":[\"200\",\"300\",\"400\",\"600\",\"700\",\"900\"],\"style\":[\"italic\",\"normal\"],\"display\":\"swap\",\"subsets\":[\"latin\"],\"variable\":\"--font-titillium-web\"}],\"variableName\":\"titillium_web\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.5_@opentelemetry+api@1.7.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/font/local/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/static/KOMIKAX.ttf\",\"display\":\"swap\",\"variable\":\"--font-komikax\"}],\"variableName\":\"komikax\"}");
;
import(/* webpackMode: "eager" */ "/app/app/css/chesedclub.webflow.css");
;
import(/* webpackMode: "eager" */ "/app/app/css/globals.css");
;
import(/* webpackMode: "eager" */ "/app/public/static/CHCLogo.svg");
;
import(/* webpackMode: "eager" */ "/app/public/static/MadaLogo.webp");
